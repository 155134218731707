import apiRequest from "./ApiService";
import { API_URL } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

const apiEndPoint = "products";

export const getProducts = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const addProduct = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}`, param);
  } catch (error) {
    console.log(error);
  }
};

export const updateProduct = async (uuid, param) => {
  try {
    return apiRequest.put(`/${apiEndPoint}/` + uuid, param);
  } catch (error) {
    console.log(error);
  }
};

export const getProductsByID = async (param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/` + param, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

// create table header
export const productTableColumns = [
  { id: "product_code", label: "Item Code" },
  { id: "product_name", label: "Product Name" },
  { id: "category", label: "Product Category" },
  { id: "price", label: "Retail Price" },
  { id: "available_stock", label: "On hand" },
  { id: "action", label: "Action" },
];

export const productHistoryByIdColumns = [
  { id: "date", label: "Date" },
  { id: "transaction", label: "Transaction" },
  { id: "qty", label: "Qty" },
];

export const dummyProduct = async () => {
  const products = [];
  try {
    for (let x = 1; x <= 1000; x++) {
      products.push({
        title: "Product name " + x,
        description: "Product description",
      });
    }
    console.table(products);
    return products;
  } catch (error) {
    console.log({ error });
  }
};

export const getSuggestedProducts = async (param = "") => {
  try {
    const res = await apiRequest.get(`/${apiEndPoint}` + param);
    if (res && res?.data) {
      let uniqueData = new Set();
      const rawList = res?.data?.data || [];
      const data = rawList.filter((el) => {
        const duplicate = uniqueData.has(el?.product_name);
        uniqueData.add(el?.product_name);
        return !duplicate;
      });

      return { data };
    }
  } catch (error) {
    console.log(error);
  }
};

export const addBarcode = async (uuid, param) => {
  try {
    return await apiRequest.get(
      `/${apiEndPoint}/generate_barcode/` + uuid,
      param
    );
  } catch (error) {
    console.log(error);
  }
};

export const uploadProductCSV = async (formData) => {
  const token = await AsyncStorage.getItem("access_token");
  return await axios
    .post(API_URL + "csv/add-products", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err?.response;
    });
};
