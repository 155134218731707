import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// images
import empty_image from "./../../../../assets/images/empty_image.svg";
import no_barcode from "./../../../../assets/images/no_barcode.png";

// component
import CustomDrawer from "../../../components/CustomDrawer";
import ViewBarcode from "./ViewBarcode";

// libs
import {
  Box,
  Grid,
  Card,
  Stack,
  CardMedia,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Button,
  Typography,
  Pagination,
} from "@mui/material";

// common
import { Alert } from "../../../components/common";

// services
import {
  getProductsByID,
  productHistoryByIdColumns as columns,
} from "../../../services/ProductService";

const ViewProducts = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [action, setAction] = useState("view barcode");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState([]);

  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");

  const [productImage, setProductImage] = useState("");
  const [barcodeImage, setBarcodeImage] = useState("");

  const location = useLocation();
  const uuid = location.pathname.split("/").pop();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const getProductDetails = () => {
    getProductsByID(uuid).then((rs) => {
      setProductData(rs.data.data);
      setProductImage(rs.data.data.image);
      setBarcodeImage(rs.data.data.barcode);
      console.log(rs.data.data.barcode);
    });
  };

  // Product history list
  const getProductHistory = (param = 1) => {
    setOpenLoader(true);
    getProductsByID(uuid + `/history?page=${param}`).then((response) => {
      setProducts(response.data.data);
      setPagination(response.data.pagination);
      console.log(response.data.data);
    });
  };

  useEffect(() => {
    getProductDetails();
    getProductHistory();
  }, []);

  // pagination
  const handleChangePage = (event, newPage) => {
    setOpenLoader(true);
    getProductHistory(newPage);
    setPage(newPage);
  };

  const handleDrawerActions = async (type) => {
    setOpenDrawer(true);
    setAction(type);
  };

  return (
    <>
      <Grid container>
        <CustomDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        >
          <ViewBarcode
            setOpenDrawer={setOpenDrawer}
            setBarcodeImage={setBarcodeImage}
            barcodeImage={barcodeImage}
          />
        </CustomDrawer>
      </Grid>
      <Box sx={{ flexGrow: 1 }} component="form" noValidate>
        <Grid container sx={{ px: "40px" }}>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography component="div" variant="h6">
              Product Name : <b> {productData?.product_name} </b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={() => setOpenDrawer(true)}
              variant="text"
              sx={{ py: "10px", margin: "10px" }}
            >
              View Barcode
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ padding: 5 }}>
          <Card sx={{ padding: "20px", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={2} display="flex" justifyContent="flex-start">
                <CardMedia
                  component="img"
                  style={{
                    border: "3px solid #fff",
                    borderRadius: "15%",
                    height: "150px",
                    width: "150px",
                  }}
                  src={productImage ? productImage : empty_image}
                  alt="Product Image"
                />
              </Grid>
              <Grid item xs={10} display="flex" justifyContent="flex-end">
                <Box
                  component="img"
                  src={barcodeImage ? barcodeImage : no_barcode}
                  alt="Barcode Image"
                  style={{
                    height: "130px",
                    width: "55%",
                    padding: "inherit",
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ padding: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Typography variant="h7" component="div">
                    <i>Product Category :</i>
                  </Typography>
                  <Typography>
                    <b>{productData?.category?.category_name}</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Brand :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.brand?.brand_name} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Number of Units :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.uom} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Stock on hand :</i>
                  </Typography>
                  <Typography>
                    <b>{productData?.product_summary?.available_stock}</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Price at pruchase :</i>
                  </Typography>
                  <Typography>
                    <b>{productData?.price_at_purchase}</b>
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="h7" component="div">
                    <i>Product Code :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.product_code} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>SKU :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.product_sku} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Unit of Measurement :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.uom_data?.name} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Low stock level :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.low_stock_level} </b>
                  </Typography>
                  <br></br>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="h7" component="div">
                    <i>Cost :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.summary?.average_cost} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Retail price cost :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.price} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Supplier :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.supplier?.supplier_name} </b>
                  </Typography>
                  <br></br>
                  <Typography variant="h7" component="div">
                    <i>Description :</i>
                  </Typography>
                  <Typography>
                    <b> {productData?.product_description} </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ paddingBottom: "40px", paddingX: "40px" }}
        >
          <Card sx={{ padding: "20px", mt: "20px", width: "100%" }}>
            <Grid item xs={12} sx={{ padding: 4 }}>
              <Typography component="div" variant="h6" sx={{ py: "15px" }}>
                Product History
              </Typography>

              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {products.length > 0 &&
                        products.map((product, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell> {product.updated_at} </TableCell>
                              <TableCell> {product.type_name} </TableCell>
                              <TableCell> {product.quantity} </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    Showing {page ? (page - 1) * rowsPerPage + 1 : 0} -{" "}
                    {page
                      ? page * rowsPerPage > pagination.total
                        ? pagination.total
                        : page * rowsPerPage
                      : 0}{" "}
                    of {pagination.total ? pagination.total : 0} matches{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack spacing={2}>
                    <Pagination
                      count={
                        pagination.total
                          ? Math.ceil(pagination.total / rowsPerPage)
                          : 0
                      }
                      page={page}
                      onChange={handleChangePage}
                      color="primary"
                      size="small"
                      showFirstButton
                      showLastButton
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  onClose={handleCloseSnack}
                  severity={snackVariant}
                  sx={{ width: "100%" }}
                >
                  {errMsg}
                </Alert>
              </Snackbar>
            </Grid>
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default ViewProducts;
