import { useEffect, useState } from "react";

// libs
import {
  Grid,
  Snackbar,
  Backdrop,
  CircularProgress,
  Alert,
  Typography,
  Box,
  Tooltip,
  LinearProgress,
  Link,
} from "@mui/material";

// services
import {
  exportStockIn,
  getImportHistory,
} from "../../../../services/StockService";
import DataRecords from "../components/DataRecords";

// sub component
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const STATUS_COLORS = {
  Pending: "orange",
  Processing: "cyan",
  Success: "lightgreen",
  Failed: "red",
};

const ImportHistory = () => {
  const [dataReport, setDateReport] = useState();
  const [transaction, setTransaction] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [date, setDate] = useState({
    start: dayjs().date(1),
    end: dayjs(new Date()),
  });
  const [rsDataPagination, setRsDataPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const schema = {
    created_at: "",
    file_url: "",
    created_by: "",
    remarks: "",
    updated_product: "",
    added_product: "",
  };

  const fetchSalesReport = async (page, pageSize) => {
    setPage(page);
    const { data } = await getImportHistory(
      `?&page=${page}&size=${pageSize}&date_start=${dayjs(date.start).format(
        "YYYY-MM-DD"
      )}&date_end=${dayjs(date.end).format("YYYY-MM-DD")}`
    );
    if (data && data?.success) {
      let rs = [];

      setTransaction(data?.data);

      data?.data?.map((d) => {
        rs.push({
          ...schema,
          id: d?.id,
          created_at: d?.created_at,
          file_url: d?.file_url,
          created_by: d?.created_by.first_name + " " + d?.created_by.last_name,
          remarks: d?.remarks,
          updated_product: d?.updated_product,
          added_product: d?.added_product,
          status: d?.status,
        });
      });
      setDateReport(rs);
      setRsDataPagination(data?.pagination);
    }
  };

  const handleClickExport = () => {
    const start = date.start.format("YYYY-MM-DD");
    const end = date.end.format("YYYY-MM-DD");
    exportStockIn(start, end);
    setOpenSnack(true);
    setSnackVariant("success");
    setErrMsg("Successfully sent file to your email address");
  };

  useEffect(() => {
    fetchSalesReport(page, pageSize);
  }, [date, pageSize]);

  const tableColumns = [
    {
      field: "created_at",
      headerName: "Date",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "file_url",
      headerName: "File",
      minWidth: 300,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Link href={params?.row?.file_url} target="_blank">
            {params?.row?.file_url || ""}
          </Link>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Uploaded By",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },

    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        console.log(params);
        return (
          <Box>
            <Typography
              sx={{ color: `${STATUS_COLORS[params.value]} !important` }}
            >
              {params.value}
            </Typography>
            {params?.value == "Processing" && <LinearProgress />}
          </Box>
        );
      },
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 400,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box p={1}>
            <Tooltip title={`${params.value}`} placement="top">
              <Box
                sx={{
                  padding: 2,
                  paddingLeft: 0,
                  maxWidth: 400, // Set a maximum height
                  maxHeight: 50,
                  overflowX: "scroll", // Prevent horizontal scrolling
                  whiteSpace: "break-spaces",
                }}
              >
                {/* Responsive Typography */}
                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: "break-word", // Wrap long words if needed
                    fontSize: {
                      xs: "0.8rem", // Small font size on extra-small screens
                      sm: "0.9rem", // Slightly larger on small screens
                      md: "1rem", // Default font size for medium screens and above
                    },
                    lineHeight: "1.5", // Adjust line height for readability
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "updated_product",
      headerName: "Updated Product",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
      hide: true,
    },
    {
      field: "added_product",
      headerName: "Added Product",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
      hide: true,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} display="flex">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date Start"
              value={date?.start}
              onChange={(newValue) =>
                setDate({
                  ...date,
                  start: newValue,
                })
              }
            />
            <DatePicker
              label="Date End"
              value={date?.end}
              onChange={(newValue) =>
                setDate({
                  ...date,
                  end: newValue,
                })
              }
            />
          </LocalizationProvider>
        </Grid>
        {/* <Grid
          item
          xs={12}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Button onClick={() => handleClickExport()}>
            <DownloadIcon></DownloadIcon>Export
          </Button>
        </Grid> */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <DataRecords
            tableColumns={tableColumns}
            tableRecords={dataReport}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => fetchSalesReport(newPage + 1, pageSize)}
            page={page}
            rowCount={rsDataPagination?.total || 0}
            responsive
          ></DataRecords>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ImportHistory;
