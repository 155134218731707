import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
// css
import GlobalStyles from "../../../../styles/GlobalStyles";

// libs
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  Button,
} from "@mui/material";
import { getRoles } from "../../../../services/RoleService";
import { addAccount } from "../../../../services/UserService";

const AddAccount = ({
  setOpenDrawer,
  getCustomerList,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
}) => {
  const [roles, setRoles] = useState([]);
  const [accountRole, setAccountRole] = useState("");
  const [formValues, setFormValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email_address: "",
    mobile_number: "",
    role_name: "",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: formValues,
  });

  // submit form add customer
  async function onSubmit(data) {
    setOpenLoader(true);
    const formData = {
      ...data,
      role_name: accountRole,
    };
    if (formData) {
      try {
        const data = await addAccount(formData);
        if (data.data.success == 1) {
          setSnackVariant && setSnackVariant("success");
          setErrMsg && setErrMsg("Account was successfully added.");
          getCustomerList();
          setOpenDrawer(false);
          setOpenSnack(true);
          reset();
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
        reset();
      } catch (error) {
        setSnackVariant && setSnackVariant("error");
        setErrMsg && setErrMsg("Something went wrong!");
        setOpenDrawer(false);
        setOpenSnack(true);
      } finally {
        reset();
      }
    }
  }

  useEffect(() => {
    getRoles().then((rs) => {
      setRoles(rs?.data?.data ?? []);
    });
  }, []);

  const [openSnackAlert, setOpenSnackAlert] = useState(false);
  const [snackVariantAlert, setSnackVariantAlert] = useState("success");
  const [errMsgAlert, setErrMsgAlert] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackAlert(false);
  };

  // loader
  const [openLoader, setOpenLoader] = React.useState(false);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ flexGrow: 1, width: "500px" }}
      noValidate
    >
      <Grid container spacing={2} style={GlobalStyles.drawerWrapper}>
        <Grid item md={12} sx={{ padding: "20px 15px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: "First name is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                    label="First Name"
                    variant="filled"
                    id="first_name"
                    inputProps={{
                      autoComplete: "none",
                    }}
                    error={!!errors.first_name}
                    helperText={errors.first_name && errors.first_name.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="middle_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                    label="Middle Name"
                    variant="filled"
                    id="middle_name"
                    inputProps={{
                      autoComplete: "none",
                    }}
                    error={!!errors.middle_name}
                    helperText={
                      errors.middle_name && errors.middle_name.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: "Last name is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    variant="filled"
                    inputProps={{
                      autoComplete: "none",
                    }}
                    error={!!errors.last_name}
                    helperText={errors.last_name && errors.last_name.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Controller
                  name="email_address"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter a valid email",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Email address"
                      variant="filled"
                      inputProps={{
                        autoComplete: "none",
                      }}
                      error={!!errors.email_address}
                      helperText={
                        errors.email_address ? "Invalid Email" : "Email Address"
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="mobile_number"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    },
                    minLength: {
                      value: 10,
                      message: "Must be 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Should not exceed to 10 digits",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+63</InputAdornment>
                        ),
                      }}
                      margin="normal"
                      type="number"
                      fullWidth
                      id="mobile_number"
                      label="Mobile No."
                      placeholder="(eg. 9XXXXXXXXX)"
                      variant="filled"
                      error={!!errors.mobile_number}
                      sx={{
                        "& input[type=number]": {
                          "-moz-appearance": "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      }}
                      helperText="( Mobile Number )"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={12}>
                <Controller
                  name="role_name"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={roles}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, values) => setAccountRole(values.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          onChange={onChange}
                          margin="normal"
                          fullWidth
                          label="Role"
                          variant="filled"
                          autoComplete="off"
                          error={!!errors.role_name}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}></Grid>
            <Grid
              item
              md={6}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={() => setOpenDrawer(false)} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="success">
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackAlert}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariantAlert}
          sx={{ width: "100%" }}
        >
          {errMsgAlert}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AddAccount;
