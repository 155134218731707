import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes/Routes";
import { UseDocumentTitle } from "../../hooks/UseDocumentTitle";
import { useNavigate } from "react-router-dom";

// assets
import AsensoLogo from "../../../assets/asenso_logo.svg";
import logo from "../../../assets/sm_logo.png";

// libs
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemTooltip,
  Badge,
  Menu,
  Collapse,
  Tooltip,
  MenuItem,
  Avatar,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { getUserById } from "../../services/ProfileService";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  NotificationsOutlined,
  AccountCircle,
} from "@mui/icons-material";

// icons
import products from "../../../assets/icons/Products.svg";
import stocks from "../../../assets/icons/stocks.svg";
import supplier from "../../../assets/icons/supplier.svg";
import ar from "../../../assets/icons/ar.svg";
import reports from "../../../assets/icons/reports.svg";
import settings from "../../../assets/icons/settings.svg";
import customer from "../../../assets/icons/customer.svg";
import earnings from "../../../assets/icons/earnings.svg";

// service
import { logout } from "../../services/AuthService";
import { set } from "react-hook-form";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: "80px",
  "&.@media": {
    minWidth: "80px",
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function CustomContainer(props) {
  const [userData, setUserData] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1];
  const currentLocationNoUnderscore = currentLocation.replace(/_/g, " ");
  const uuid = localStorage.getItem("uuid");
  const [documentTitle, setDocumentTitle] = UseDocumentTitle(
    currentLocationNoUnderscore == "manage account"
      ? "Manage Profile" // if manage account
      : currentLocation == "product_profile"
      ? "Product Profile" // if products
      : currentLocation == "customer_profile"
      ? "Customer Profile" // if customers
      : currentLocation == "return_and_replace"
      ? "Return And Replace" // if return
      : currentLocation == "oms"
      ? "Ordering Management System" // if pos
      : currentLocation
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const rolePagesString = String(localStorage.getItem("role_pages"));
  const rolePages = JSON.parse(rolePagesString);
  const filteredRoutes = routes.filter((route) => {
    return rolePages?.find(
      (row) => row.page_name.toLowerCase() === route.text.toLowerCase()
    );
  });
  const [subItemMargin, setSubItemMargin] = React.useState("-40px");

  React.useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    getUserById(uuid).then((rs) => {
      setUserData(rs.data.data);
    });
  };

  const handleDrawerOpen = () => {
    setSubItemMargin("0px");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setSubItemMargin("-40px");
    setOpen(false);
  };

  const handleListItemClick = (event, index, title) => {
    switch (title) {
      case "Stock Room":
        setOpenStocks(!openStocks);
        break;
      case "Reports":
        setOpenReports(!openReports);
        break;
      case "Settings":
        setOpenSettings(!openSettings);
        break;
      case "Manage Profile":
        setAnchorEl(null);
        navigate("/manage_account");
      case "POS":
        setDocumentTitle("Ordering Management System");
        break;
      default:
        setDocumentTitle(title);
        break;
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogOut = () => {
    logout().then(function () {
      navigate("/login");
    });
  };

  const [openCollapse, setOpenCollapse] = React.useState(false);

  const [openReports, setOpenReports] = React.useState(false);
  const [openStocks, setOpenStocks] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: "#194B80" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "40px",
                marginRight: "20px",
                marginLeft: "-5px",
                padding: "7px",
              }}
            />
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {documentTitle.toUpperCase()}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "flex", md: "flex", alignItems: "center" } }}
          >
            {/* <IconButton
              size='large'
              aria-label='show 17 new notifications'
              color='inherit'
            >
              <Badge badgeContent={17} color='error'>
                <NotificationsOutlined />
              </Badge>
            </IconButton> */}
            <Typography variant="h7" noWrap component="div">
              {localStorage.getItem("store_name")}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {userData?.store?.logo ? (
                <Avatar src={userData?.store?.logo} />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => handleListItemClick("", "", "Manage Profile")}
              >
                Manage Profile
              </MenuItem>
              <MenuItem onClick={userLogOut}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ height: "60px !important" }}>
          <img src={AsensoLogo} style={{ width: "138px", height: "47px" }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon></ChevronLeftIcon>
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {filteredRoutes.map((item, index) => {
            const { text, icon, is_hidden, tooltip } = item;
            return (
              <ListItem
                sx={(is_hidden && { display: "none" }) || { display: "block" }}
                component={Link}
                to={item.to}
                button
                key={index}
                disablePadding
                selected={item.to === location.pathname}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={(event) =>
                    handleListItemClick(event, index, item.text)
                  }
                >
                  {/* {tooltip && (
                    <List
                      placement='right-start'
                      sx={{
                        ':hover': { tooltip },
                      }}
                    >
                      {tooltip}
                    </List>
                  )} */}
                  {icon && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                  )}
                  {/* {tooltip && <Tooltip>{text}</Tooltip>} */}
                  {/* {tooltip && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {text}
                    </ListItemIcon>
                  )} */}

                  <ListItemText
                    primary={text === "POS" ? "OMS" : text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  <ListItemText sx={{ ":hover": { tooltip } }} />
                  {item.sub_routes && item.sub_routes.length > 0 ? (
                    <ListItemIcon
                      sx={{ minWidth: 0, ml: "auto", color: "inherit" }}
                    >
                      {text == "Stock Room" && open ? (
                        openStocks ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                      {text == "Reports" && open ? (
                        openReports ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}

                      {text == "Settings" && open ? (
                        openSettings ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </ListItemIcon>
                  ) : null}
                </ListItemButton>
                {item.sub_routes && item.sub_routes.length > 0 ? (
                  text == "Stock Room" ? (
                    <Collapse in={openStocks} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.sub_routes.map((subItem, subIndex) => {
                          const { text, icon } = subItem;
                          return (
                            <ListItem
                              component={Link}
                              to={subItem.to}
                              button
                              key={subIndex}
                              disablePadding
                              selected={subItem.to === location.pathname}
                              sx={{ pl: 5 }}
                              onClick={(event) =>
                                handleListItemClick(
                                  event,
                                  subIndex,
                                  subItem.text
                                )
                              }
                            >
                              <ListItemButton
                                sx={{
                                  marginLeft: subItemMargin,
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 2.5,
                                }}
                              >
                                {icon && (
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {icon}
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primary={text}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  ) : null
                ) : null}

                {item.sub_routes && item.sub_routes.length > 0 ? (
                  text == "Reports" ? (
                    <Collapse in={openReports} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.sub_routes.map((subItem, subIndex) => {
                          const { text, icon } = subItem;
                          return (
                            <ListItem
                              component={Link}
                              to={subItem.to}
                              button
                              key={text}
                              disablePadding
                              selected={subItem.to === location.pathname}
                              sx={{ pl: 5 }}
                              onClick={(event) =>
                                handleListItemClick(
                                  event,
                                  subIndex,
                                  subItem.text
                                )
                              }
                            >
                              <ListItemButton
                                sx={{
                                  marginLeft: subItemMargin,
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 2.5,
                                }}
                              >
                                {icon && (
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {icon}
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primary={text}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  ) : null
                ) : null}

                {item.sub_routes && item.sub_routes.length > 0 ? (
                  text == "Settings" ? (
                    <Collapse in={openSettings} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.sub_routes.map((subItem, subIndex) => {
                          const { text, icon } = subItem;
                          return (
                            <ListItem
                              component={Link}
                              to={subItem.to}
                              button
                              key={text}
                              disablePadding
                              selected={subItem.to === location.pathname}
                              sx={{ pl: 5 }}
                              onClick={(event) =>
                                handleListItemClick(
                                  event,
                                  subIndex,
                                  subItem.text
                                )
                              }
                            >
                              <ListItemButton
                                sx={{
                                  marginLeft: subItemMargin,
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 2.5,
                                }}
                              >
                                {icon && (
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {icon}
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primary={text}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  ) : null
                ) : null}
              </ListItem>
              //   ) : (
              //     <div
              //     sx={{ pl: 0 }}
              //     component='nav'
              //     aria-labelledby='nested-list-subheader'
              //   >
              //     <ListItem
              //     >
              //       <ListItemButton
              //         onClick={handleClickCollapse}
              //         sx={{ pl: 1, pt: 0 }}
              //       >
              //         <ListItemIcon>
              //           <img src={stocks} />
              //         </ListItemIcon>
              //         <ListItemText primary='Stock Room' />
              //         {openCollapse ? <ExpandLess /> : <ExpandMore />}
              //       </ListItemButton>
              //       <Collapse in={openCollapse} timeout='auto' unmountOnExit>
              //         <List component='div'>
              //           <ListItemButton sx={{ pl: 5 }} to='/products'>
              //             <ListItemIcon>
              //               <img src={products} />
              //             </ListItemIcon>
              //             <ListItemText primary='Products' />
              //           </ListItemButton>

              //           <ListItemButton sx={{ pl: 5 }} to='/stocks'>
              //             <ListItemIcon>
              //               <img src={stocks} />
              //             </ListItemIcon>
              //             <ListItemText primary='Stock Room' />
              //           </ListItemButton>

              //           <ListItemButton sx={{ pl: 5 }} to='/supplier'>
              //             <ListItemIcon>
              //               <img src={supplier} />
              //             </ListItemIcon>
              //             <ListItemText primary='Supplier' />
              //           </ListItemButton>
              //         </List>
              //       </Collapse>
              //     </ListItem>
              //     </div>
              //   )}
              // </>
            );
          })}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}
