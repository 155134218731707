import React, { useEffect, useState } from "react";
import {
  Box,
  InputAdornment,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Stack,
  Backdrop,
  CircularProgress,
  TableSortLabel,
  Snackbar,
  Switch,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

// services
import {
  getUsers,
  accountTableColumns as columns,
  updateUserStatus, // Axios service for PUT request
} from "../../../../services/UserService";
import CustomDrawer from "../../../../components/CustomDrawer";
import AddAccount from "./AddAccount";
import { Alert } from "../../../../components/common";

const ListAccounts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accounts, setAccounts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLoader, setOpenLoader] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [action, setAction] = useState("add");
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const getUsersAsync = setTimeout(async () => {
      if (searchText === "") {
        getUserList();
      } else {
        setOpenLoader(true);
        const productList = await getUsers(`/search?keyword=${searchText}`);
        if (productList?.data?.data) {
          setAccounts(productList.data.data);
          setPage(0);
          setPagination(productList.data.pagination);
          setOpenLoader(false);
        }
      }
    }, 1000);
    return () => clearTimeout(getUsersAsync);
  }, [searchText]);

  useEffect(() => {
    getUserList();
  }, [page, rowsPerPage]);

  const getUserList = () => {
    setOpenLoader(true);
    getUsers(
      `?page=${page + 1}&size=${rowsPerPage}&sort=${sortBy}&order=${sortOrder}`
    ).then((response) => {
      setAccounts(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
  };

  const [sortBy, setSortBy] = useState("first_name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (label) => {
    const newOrder = label === sortBy && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(label);
    setSortOrder(newOrder);
    setOpenLoader(true);
    getUsers(
      `?page=1&size=${rowsPerPage}&sort=${label}&order=${newOrder}`
    ).then((response) => {
      setAccounts(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSwitchToggle = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleModalConfirm = async () => {
    const newStatus = selectedRow?.status === 3 ? 4 : 3; // Toggle status
    try {
      setOpenLoader(true);
      const response = await updateUserStatus(selectedRow.uuid, newStatus);
      setErrMsg(
        `User ${newStatus === 3 ? "activated" : "deactivated"} successfully`
      );
      setSnackVariant("success");
      setOpenSnack(true);
      getUserList(); // Refresh the list
    } catch (error) {
      setErrMsg("Failed to update user status");
      setSnackVariant("error");
      setOpenSnack(true);
    } finally {
      setOpenLoader(false);
      setModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnack(false);
  };

  return (
    <>
      <Grid container sx={{ mt: 0, mb: 0 }}>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search Accounts"
            label="Search Accounts"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "white" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 0, mb: 0 }}
          >
            <CustomDrawer
              clickTableRow={() => {}}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              setAction={setAction}
              action={action}
            >
              <AddAccount
                setOpenDrawer={setOpenDrawer}
                getCustomerList={getUserList}
                setOpenSnack={setOpenSnack}
                setSnackVariant={setSnackVariant}
                setErrMsg={setErrMsg}
              />
            </CustomDrawer>
          </Grid>
        </Grid>
      </Grid>
      <Box
        m={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      ></Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 560 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.id === "action" ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={sortBy === column.id ? sortOrder : "asc"}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts && accounts.length > 0 ? (
                accounts.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.middle_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.email_address}</TableCell>
                    <TableCell>{row?.role}</TableCell>
                    <TableCell>{row?.mobile_number}</TableCell>
                    <TableCell>{row?.status_name}</TableCell>
                    <TableCell>
                      {[3, 4].includes(Number(row.status)) && (
                        <Switch
                          color="success"
                          checked={row.status === 3}
                          onChange={() => handleSwitchToggle(row)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlignLast: "center" }}>
                    No record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        ></Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack spacing={2}>
            <TablePagination
              component="div"
              count={pagination.total ? pagination.total : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert severity={snackVariant}>{errMsg}</Alert>
      </Snackbar>
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to{" "}
            {selectedRow?.status === 3 ? "deactivate" : "activate"} this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleModalConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListAccounts;
