import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";

// global css
import GlobalStyles from "../../styles/GlobalStyles";

// assets
import LoginImage from "../../../assets/images/login-bg.png";

// libs
import {
  CssBaseline,
  TextField,
  Box,
  Grid,
  Typography,
  Snackbar,
  Link,
  IconButton,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";

// icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

// common
import { Logo, Alert } from "../../components/common";

// service
import { login } from "../../services/AuthService";

const Login = () => {
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  // submit form user login
  const userLogin = (data) => {
    setOpenLoader(true);
    login(data)
      .then(function (rs) {
        AsyncStorage.setItem("access_token", rs.data.data.access_token);
        AsyncStorage.setItem("store_id", rs.data.data.user.store_id);
        AsyncStorage.setItem("user_id", rs.data.data.user.id);
        AsyncStorage.setItem("uuid", rs.data.data.user.uuid);
        AsyncStorage.setItem("pages", rs.data.data.user.pages);
        AsyncStorage.setItem(
          "role_pages",
          JSON.stringify(rs?.data?.data?.user?.role_pages)
        );
        AsyncStorage.setItem("store_name", rs.data.data.user.store.name);
        switch (rs.data.data.user.role) {
          case "store-admin":
            window.location.href = "/";
            break;
          case "cashier":
            window.location.href = "/oms";
            break;
          case "inventory-clerk":
            window.location.href = "/products";
            break;
          case "accounting":
            window.location.href = "/transactions";
            break;
          default:
            window.location.href = "/";
        }
      })
      .catch(function (err) {
        setErrMsg("Invalid username or password.");
        setOpenSnack(true);
        setOpenLoader(false);
      });
  };

  // close snackbar
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  // show password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // detect caps lock
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  // This function is triggered on the keyup event
  const checkCapsLock = (event) => {
    if (event.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <Grid sx={{ backgroundColor: "#12355B" }}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", backgroundColor: "#12355B" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              padding: 7,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(userLogin)}
              sx={{ mt: 8, textAlign: "left" }}
            >
              <Typography component="h1" variant="h5">
                Hi!
              </Typography>
              <Controller
                name="username"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    id="username"
                    label="User Name"
                    name="username"
                    autoFocus
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.username}
                    helperText={errors.username && "Username is required."}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    onKeyUp={checkCapsLock}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.password}
                    helperText={
                      (errors.password && "Password is required.") ||
                      (isCapsLockOn && "Caps lock is on.")
                    }
                    {...field}
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Continue
              </Button>
              <Grid container>
                <Grid item xs sx={{ textAlign: "left" }}>
                  <Link
                    sx={{ color: GlobalStyles.primaryText }}
                    href="/reset"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default Login;
