import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { View } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import {
  Button,
  Snackbar,
  TextField,
  Box,
  Stack,
  Alert,
  Grid,
  CardMedia,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";

// css
import GlobalStyles from "../../../styles/GlobalStyles";

// common

//images
import empty_image from "../../../../assets/images/empty_image.svg";

// services
import {
  AddSuppliers,
  updateSupplier,
} from "../../../services/StoreParameter/SupplierService";
import { uploadPhoto } from "../../../services/UploadService";
import { LoadingButton } from "@mui/lab";

const FormSupplier = ({ onDataListChange, action }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [supplier_name, setSupplierName] = useState("");
  const [supplier_address, setSupplierAddress] = useState("");
  const [supplier_email_address, setSupplierEmail] = useState("");
  const [supplier_contact_number, setSupplierNumber] = useState("");

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "success" });
  const [isAddAction, setIsAddAction] = useState(true);

  useEffect(() => {
    onDataListChange(true);

    // update from list
    if (action && action?.uuid) {
      setIsAddAction(false);

      // from update component
      setSupplierName(action?.supplier_name || "");
      setSupplierAddress(action?.supplier_address || "");
      setSupplierEmail(action?.supplier_email_address || "");
      setSupplierNumber(action?.supplier_contact_number || "");
      setSupplierImage(action?.supplier_image);
      setFormSupplierImage(action?.supplier_image);
    } else {
      setIsAddAction(true);
    }
  }, [action]);

  // uploading of photo
  const [supplier_image, setSupplierImage] = useState(null);
  const [formSupplierImage, setFormSupplierImage] = useState(null);

  // snackbar for unsupported file type
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      copyToCacheDirectory: false,
    });
    if (result.canceled) {
      return;
    }

    setIsUploading(true);
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setSupplierImage(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setFormSupplierImage(link.data.link);
      }

      setIsUploading(false);
    } else {
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Please select valid image.");

      setIsUploading(false);
    }
  };

  const addRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    const created_by = (await AsyncStorage.getItem("user_id")) || null;
    const supplier_image = formSupplierImage;

    try {
      onDataListChange(false);
      const { data } = await AddSuppliers({
        store_id,
        created_by,
        supplier_image,
        supplier_name,
        supplier_address,
        supplier_email_address,
        supplier_contact_number,
      });

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
      } else {
        setAlert({
          message: data?.message?.supplier_name[0] || null,
          type: "warning",
        });
        setOpen(true);

        console.log(
          "[fail add supplier] : ",
          data?.message?.supplier_name[0] || null
        );
      }
      setOpenLoader(false);
    } catch (error) {
      console.log("[err add supplier] : ", error);

      setAlert({
        message: "The supplier email address must be a valid email address.",
        type: "error",
      });
      setOpen(true);
    }
  };

  const updateRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    try {
      onDataListChange(false);
      const param = {
        supplier_name,
        supplier_address,
        supplier_email_address,
        supplier_contact_number,
        supplier_image: formSupplierImage,
      };
      const { data } = await updateSupplier(action?.uuid, param);

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
        setFormSupplierImage(null);
      } else {
        console.log("[fail add supplier] : ", data.message || null);
      }
      setOpenLoader(false);
    } catch (error) {
      console.log("[err add supplier] : ", error);

      setAlert({
        message: "The supplier email address must be a valid email address.",
        type: "error",
      });
      setOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // form validate
    const errors = {};
    if (!supplier_name) {
      errors.supplier_name = "Name is required";
    }

    // submission
    if (isAddAction) {
      setOpenLoader(true);
      await addRecord();
    } else {
      setOpenLoader(true);
      await updateRecord();
    }

    // whenver errors occurs, set them in state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
  };

  const cancelForm = async () => {
    onDataListChange(false);
    setSupplierName("");
    setSupplierAddress("");
    setSupplierEmail("");
    setSupplierNumber("");
    setSupplierImage("");
    setErrors({});
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // loader
  const [openLoader, setOpenLoader] = React.useState(false);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 1, mt: 4, mb: 2 }} variant="h6" component="div">
            Supplier Form
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <View>
              <Grid container style={GlobalStyles.productWrapper}>
                <Grid item xs={4}>
                  {isUploading ? (
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      style={GlobalStyles.productImage}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <CardMedia
                      name="supplier_image"
                      style={GlobalStyles.productImage}
                      component="img"
                      src={supplier_image ? supplier_image : empty_image}
                      alt=""
                      onClick={pickImage}
                      onChange={(e) => setSupplierImage(e.target.value)}
                    />
                  )}
                </Grid>
              </Grid>
            </View>
            <div>
              <TextField
                fullWidth
                id="input-supplier-name"
                label="Supplier Name"
                margin="normal"
                variant="filled"
                required
                type="text"
                value={supplier_name}
                inputProps={{ autoComplete: "none" }}
                onChange={(e) => setSupplierName(e.target.value)}
                error={errors.supplier_name ? true : false}
                helperText={
                  errors.supplier_name
                    ? "Supplier name is required"
                    : "( Supplier name )"
                }
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="input-supplier-address"
                label="Supplier Address"
                margin="normal"
                variant="filled"
                type="text"
                inputProps={{ autoComplete: "none" }}
                value={supplier_address}
                onChange={(e) => setSupplierAddress(e.target.value)}
                helperText={"( Supplier address )"}
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="input-supplier-email"
                label="Supplier Email Address"
                margin="normal"
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                type="email"
                value={supplier_email_address}
                onChange={(e) => setSupplierEmail(e.target.value)}
                error={!!errors.supplier_email_address}
                helperText={
                  errors.supplier_email_address
                    ? "Invalid Email"
                    : "( Supplier Email Address )"
                }
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="input-supplier-number"
                label="Supplier Contact Number"
                margin="normal"
                variant="filled"
                type="number"
                inputProps={{ autoComplete: "none" }}
                value={supplier_contact_number}
                onChange={(e) => setSupplierNumber(e.target.value)}
                helperText={"( Supplier contact number )"}
                sx={{
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  const paste = event.clipboardData.getData("text");
                  if (paste.match(/[^\d]/)) {
                    setSupplierNumber(
                      supplier_contact_number + paste.replace(/[^\d]/g, "")
                    );
                    event.preventDefault();
                  }
                }}
              />
            </div>

            <Stack
              spacing={2}
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={3}
            >
              <Button
                variant="outlined"
                onClick={cancelForm}
                sx={{ minWidth: 150 }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ minWidth: 150 }}
                disabled={isUploading}
                variant={isUploading ? "outlined" : "contained"}
                type="submit"
                color="success"
              >
                {isAddAction ? "Add" : "Update"}
              </LoadingButton>
            </Stack>

            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={alert?.type}
                sx={{ width: "100%" }}
              >
                {alert?.message || "Supplier successfully added"}
              </Alert>
            </Snackbar>

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={snackVariant}
                sx={{ width: "100%" }}
              >
                {errMsg}
              </Alert>
            </Snackbar>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FormSupplier;
