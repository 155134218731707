import apiRequest from "./ApiService";

const apiEndPoint = "roles";

export const getRoles = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};
