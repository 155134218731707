import React, { useEffect, useState } from "react";

// global css

// component
import CustomDrawer from "../../../components/CustomDrawer";
import AddProducts from "./AddProducts";
import EditProducts from "./EditProducts";
import ViewProducts from "./ViewProducts";
import Modal from "@mui/material/Modal";

import * as DocumentPicker from "expo-document-picker";
import { uploadProductCSV } from "../../../services/ProductService";

import { Visibility, Edit } from "@mui/icons-material";

// libs
import {
  Button,
  Box,
  InputAdornment,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Stack,
  Typography,
  IconButton,
  Snackbar,
  Backdrop,
  CircularProgress,
  TableSortLabel,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

import { Alert } from "../../../components/common";

// services
import {
  getProducts,
  getProductsByID,
  productTableColumns as columns,
} from "../../../services/ProductService";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#050F1A",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

const ListProducts = () => {
  const navigate = useNavigate();
  const [isImporting, setIsImporting] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState("add");
  const [productData, setProductData] = useState([]);
  const [uuid, setUuid] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = useState("");

  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(true);

  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setAnchorEl(null);
    const getProductsAsync = setTimeout(async () => {
      if (searchText == "") {
        getProductList();
      } else {
        setOpenLoader(true);
        const productList = await getProducts(`/search?keyword=` + searchText);

        if (productList?.data?.data) {
          setProducts(productList?.data?.data);
          setPage(0);
          setPagination(productList?.data?.pagination);
          setOpenLoader(false);
        }
      }
    }, 1000);
    return () => clearTimeout(getProductsAsync);
  }, [searchText]);

  useEffect(() => {
    getProductList();
  }, [page, rowsPerPage]);

  const getProductList = () => {
    setOpenLoader(true);
    getProducts(
      `?page=${page + 1}&size=${rowsPerPage}&sort=${label}&order=${sortOrder}`
    ).then((response) => {
      setProducts(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
  };

  // product sort by table label
  const [sortBy, setSortBy] = useState("product_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const label = sortBy;

  const handleSort = (label) => {
    if (label === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    }
    if (label == "retail_price") {
      setSortBy("price");
      label = "price";
    } else if (label == "on_hand") {
      setSortBy("available_stock");
      label = "available_stock";
    } else {
      setSortBy(label);
    }
    setOpenLoader(true);
    getProducts(
      `?page=1&size=${rowsPerPage}&sort=${label}&order=${sortOrder}`
    ).then((response) => {
      setProducts(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // pagination rows per page
  const handleChangeRowsPerPage = (event) => {
    setOpenLoader(true);
    getProducts(
      `?page=1&size=${+event.target.value}&sort=${label}&order=${sortOrder}`
    ).then((response) => {
      setProducts(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  const clickTableRow = async (uuid) => {
    setProductData(null);
    if (uuid) {
      const response = await getProductsByID(uuid);
      if (response && response?.data?.data) {
        setUuid(uuid);
        setProductData(response?.data?.data);
        setOpenDrawer(true);
        setAction("edit");
      }
    }
  };

  const downloadProductCSV = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
    handleCloseModal();
  };

  // import csv
  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "text/csv",
      multiple: false,
      copyToCacheDirectory: false,
    });
    if (result.canceled) {
      return;
    }
    try {
      if (
        result.type === "success" &&
        result.name.split(".").pop().toLowerCase() === "csv"
      ) {
        setIsImporting(true);
        let formData = new FormData();
        formData.append("file", result.file);
        uploadProductCSV(formData);
        setTimeout(() => {
          navigate("/import_history");
        }, 2000);
        setSnackVariant("success");
        setErrMsg("Processing import of products...");
        setOpenSnack(true);
        setOpenLoader(false);
        handleCloseModal();
        // if (resp?.data?.success === 1) {
        //   setSnackVariant("success");
        //   setErrMsg(resp?.data?.message ?? "Something went wrong");
        //   setOpenSnack(true);
        //   setOpenLoader(false);
        //   handleCloseModal();
        //   getProductList();
        // } else {
        //   setSnackVariant("error");
        //   setErrMsg(resp?.data?.message ?? "Something went wrong");
        //   setOpenSnack(true);
        //   setOpenLoader(false);
        //   setOpenModal(false);
        // }
        setIsImporting(false);
      } else {
        setSnackVariant("error");
        setErrMsg("Something went wrong");
        setOpenSnack(true);
        setOpenLoader(false);
        setOpenModal(false);
      }
    } catch (error) {
      setSnackVariant("error");
      setErrMsg("Failed to import CSV file");
      setOpenSnack(true);
      setOpenLoader(false);
      setOpenModal(false);
      setIsImporting(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search Products"
            label="Search Products"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "white" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpenModal(true)}
          >
            Import Products
          </Button>
          <CustomDrawer
            clickTableRow={clickTableRow}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
            setAction={setAction}
            action={action}
          >
            {action === "add" && (
              <AddProducts
                setOpenDrawer={setOpenDrawer}
                getProductList={getProductList}
                setOpenSnack={setOpenSnack}
                setSnackVariant={setSnackVariant}
                setErrMsg={setErrMsg}
                setAction={setAction}
                action={action}
              />
            )}
            {action === "edit" && productData && (
              <EditProducts
                productData={productData}
                uuid={uuid}
                setOpenDrawer={setOpenDrawer}
                getProductList={getProductList}
                setOpenSnack={setOpenSnack}
                setSnackVariant={setSnackVariant}
                setErrMsg={setErrMsg}
              />
            )}
            {action === "view" && (
              <ViewProducts
                productData={productData}
                uuid={uuid}
                // setOpenDrawer={setOpenDrawer}
                getProductList={getProductList}
                setOpenSnack={setOpenSnack}
                setSnackVariant={setSnackVariant}
                setErrMsg={setErrMsg}
              />
            )}
          </CustomDrawer>
        </Grid>
      </Grid>
      <Box
        m={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      ></Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 560 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  //  with sort icon
                  <TableCell key={column.id}>
                    {column.id == "action" ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        onClick={() => {
                          handleSort(column.id);
                        }}
                        sx={{ color: "white" }}
                        active={label === column.id}
                        direction={label === column.id ? sortOrder : "asc"}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products && products.length > 0 ? (
                products.length > 0 &&
                products.map((product, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>{product.product_code}</TableCell>
                      <TableCell>{product.product_name}</TableCell>
                      <TableCell>{product?.category_name}</TableCell>
                      <TableCell>
                        {Number(product.price).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>
                        {product.product_summary?.available_stock}
                      </TableCell>

                      <TableCell>
                        <Link
                          href={`/product_profile/${product.uuid}`}
                          target="_blank"
                        >
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </Link>

                        <IconButton
                          sx={{ padding: "0px" }}
                          onClick={() => clickTableRow(product.uuid)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlignLast: "center" }}>
                    No record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        ></Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack spacing={2}>
            <TablePagination
              component="div"
              count={pagination.total ? pagination.total : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* modal  */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            fontWeight="bold"
            component="h2"
            textAlign="center"
          >
            Import CSV
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            1. Download the CSV template file.
          </Typography>
          <LoadingButton
            fullWidth
            variant="contained"
            color="success"
            onClick={() =>
              downloadProductCSV(
                "https://s3.ap-southeast-1.amazonaws.com/asenso.drive/asenso_new_products.csv"
              )
            }
            sx={{ mt: 1 }}
          >
            Download Template
          </LoadingButton>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            2. Import the filled CSV file.
          </Typography>
          <LoadingButton
            fullWidth
            loading={isImporting}
            variant="contained"
            color="success"
            onClick={() => pickFile()}
            sx={{ mt: 1 }}
          >
            Import CSV
          </LoadingButton>
          {isImporting && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ListProducts;
