import { padding } from "@mui/system";
import { StyleSheet } from "react-native";

const GlobalStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  // ASENSO BACKGROUNDS
  primaryBg: {
    backgroundColor: "#309737",
  },

  secondaryBg: {
    backgroundColor: "#0F2D4D",
  },

  tertiaryBg: {
    backgroundColor: "#194B80",
  },

  infoBg: {
    backgroundColor: "#2A7DD5",
  },

  lightBg: {
    backgroundColor: "#909090",
  },
  darkBg: {
    backgroundColor: "#1C1B1F",
  },

  tableEvenBg: {
    backgroundColor: "#02101F",
  },
  tableEvenBgOdd: {
    backgroundColor: "#0F2D4D",
  },
  modalBg: {
    backgroundColor: "#0a1e33",
  },
  // asenso background colors

  // asenso text
  primaryText: {
    color: "#309737",
  },

  secondaryText: {
    color: "#0F2D4D",
  },

  tertiaryText: {
    color: "#194B80",
  },

  infoText: {
    color: "#2A7DD5",
  },

  lightText: {
    color: "#909090",
  },
  white: {
    color: "#fff",
  },
  // asenso text

  // asenso buttons
  btnWrapper: {
    // display: 'flex',
    // alignSelf: 'end',
  },
  primaryBtn: {
    backgroundColor: "#309737",
    borderColor: "#309737",
    "&:hover": {
      backgroundColor: "#fff",
    },
    borderRadius: "50px",
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
  secondaryBtn: {
    backgroundColor: "#0F2D4D",
    borderColor: "#0F2D4D",
  },
  tertiaryBtn: {
    backgroundColor: "#194B80",
    borderColor: "#194B80",
  },
  infoBtn: {
    backgroundColor: "#2A7DD5",
    borderColor: "#2A7DD5",
  },
  lightBtn: {
    backgroundColor: "#909090",
    borderColor: "#909090",
    borderRadius: "50px",
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
  // asenso buttons

  productImageContainer: {
    width: "185px",
    height: "200px",
    display: "flex",
    margin: "auto",
  },
  productImage: {
    border: "3px solid #fff",
    borderRadius: "15%",
    height: "150px",
    width: "150px",
    objectFit: "fill",
    cursor: "pointer",
  },
  productWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawerWrapper: {
    padding: "30px",
    // backgroundColor: '#0A294A',
  },
  halfField: {
    width: "48%",
    margin: "3px",
  },
  fullField: {
    width: "100%",
    margin: "3px",
  },

  ListCategory: {
    maxHeight: "650px",
    overflow: "auto",
  },

  searchInput: {
    backgroundColor: "#2A7DD5",
    color: "#fff",
    width: "262px",
    borderRadius: "28px",
  },

  // error msg
  errorMsg: {
    color: "#d50000",
  },

  //CUSTOMER STATUS
  statusActive: {},

  "&.rti--input": {
    width: "100%",
  },
});

export default GlobalStyles;
