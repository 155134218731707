import apiRequest from "./ApiService";

const apiEndPoint = "users";

export const getUsers = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getUserByID = async (param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/` + param, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

// create table header
export const accountTableColumns = [
  { id: "first_name", label: "First Name" },
  { id: "middle_name", label: "Middle Name" },
  { id: "last_name", label: "Last Name" },
  { id: "email_address", label: "Email Address" },
  { id: "role", label: "Role" },
  { id: "mobile_number", label: "Mobile Number" },
  { id: "status_name", label: "Status" },
  { id: "active", label: "Active" },
];

export const addAccount = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}`, param);
  } catch (error) {
    console.log(error);
  }
};

export const updateUserStatus = async (uuid, status) => {
  try {
    return await apiRequest.put(`users/${uuid}/status?status=${status}`);
  } catch (error) {
    return error;
  }
};
