import {
  Badge,
  Grid,
  Button,
  ImageListItemBar,
  Box,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";

// images
import default_image from "./../../../../../assets/images/default_product_image.png";
import { formatPrice } from "../../../../utils/Strings";

export default function PosProducts(props) {
  const { cart, products, handlePressItem, isSuccessTransaction } = props;
  console.log("products", products);
  return (
    <Grid
      container
      sx={{
        width: "100%",
        maxHeight: "60vh",
        overflow: "hidden",
        overflowY: "scroll",
        pt: 2,
      }}
    >
      {products.map((item, index) => (
        <Grid
          key={index}
          item
          xs={4}
          sm={3}
          md={cart.length ? 4 : 2}
          lg={cart.length ? 4 : 2}
        >
          <Button
            sx={{
              display: "list-item",
              background: "transparent",
              maxHeight: 250,
              textAlign: "center",
              maxWidth: 130,
            }}
            onClick={() =>
              Number(item?.available_stock) > 0 && handlePressItem(item)
            }
            disabled={
              isSuccessTransaction ||
              Number(item?.available_stock) <= 0 ||
              item?.status === 0
            }
          >
            <Badge
              badgeContent={
                cart?.find((row) => row.id === item.id)?.quantity || 0
              }
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 14,
                  height: 30,
                  minWidth: 30,
                  borderRadius: 30,
                  fontWeight: "bold",
                },
              }}
            >
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                {(item?.available_stock <= 0 || item?.status === 0) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%, -50%)",
                    }}
                  >
                    {item?.status === 0 ? "Inactive" : "Out of Stock"}
                  </div>
                )}
                <img
                  src={item?.image || default_image}
                  alt={item.title}
                  loading="lazy"
                  height="115.31px"
                  width="115.31px"
                  style={{
                    borderRadius: 10,
                    opacity:
                      Number(item?.available_stock <= 0) ||
                      isNaN(item?.available_stock) ||
                      item?.status === 0
                        ? "50%"
                        : "100%",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = default_image;
                  }}
                />
              </div>
            </Badge>
            <ImageListItemBar
              title={
                <Stack>
                  <Typography
                    textAlign="left"
                    whiteSpace="pre-wrap"
                    style={{
                      fontSize: "10px",
                      maxHeight: 60,
                    }}
                  >
                    {item?.product_code}
                  </Typography>
                  <Tooltip title={item.product_name} placement="top-start">
                    <Typography
                      textAlign="left"
                      whiteSpace="pre-wrap"
                      style={{
                        fontSize: "12px",
                        maxHeight: 60,
                      }}
                    >
                      {item.product_name}
                    </Typography>
                  </Tooltip>
                </Stack>
              }
              subtitle={
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    marginTop: 10,
                    color: "#FFFFFF",
                  }}
                >
                  <div>{formatPrice(item?.price)}</div>{" "}
                  <div>
                    {Number(item?.available_stock) > 0
                      ? item.available_stock
                      : 0}
                  </div>
                </div>
              }
              position="below"
            />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
