import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  Card,
  Snackbar,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import GlobalStyles from "../../../styles/GlobalStyles";
import { Alert } from "../../../components/common";
import { getUserById } from "../../../services/ProfileService";
import { requestStoreInvoice } from "../../../services/InvoiceService";
//components
import CustomDrawer from "../../../components/CustomDrawer";
import UpdateProfile from "./UpdateProfile";
import UpdateStore from "./UpdateStore";
import UpdatePassword from "./UpdatePassword";
import { LoadingButton } from "@mui/lab";

export default function ManageAccount() {
  // drawer
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState("profile");
  const [productData, setProductData] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const uuid = localStorage.getItem("uuid");
  const [userData, setUserData] = useState({});
  const [openLoader, setOpenLoader] = useState(true);

  const handleDrawerActions = async (type) => {
    // if (uuid) {
    // const response = await getProductsByID(uuid);
    // if (response && response?.data?.data) {
    // setUuid(uuid);
    // setProductData(response.data.data);
    setOpenDrawer(true);
    setAction(type);
    // }
    // }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const getUserDetails = () => {
    getUserById(uuid).then((rs) => {
      setUserData(rs.data.data);
      setOpenLoader(false);
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleInvoice = async () => {
    setIsLoading(true);
    try {
      const response = await requestStoreInvoice();
      if (response?.data?.success) {
        setSnackVariant("success");
        setErrMsg(response?.data?.message);
        setOpenSnack(true);
      } else {
        setSnackVariant("error");
        setErrMsg(
          response?.data?.message ?? "Something went wrong, please try again"
        );
        setOpenSnack(true);
      }
    } catch {
      setSnackVariant("error");
      setErrMsg("Something went wrong, please try again");
      setOpenSnack(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2} columns={{ xs: 12, md: 12 }}>
      <Grid xs={12} md={6} sx={{ pl: "20px" }}>
        <Card variant="outlined" sx={{ p: 3, mb: 2, minHeight: 250 }}>
          <Box
            mb={2}
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Typography variant="h7" fontWeight={700} component="div">
              User Profile
            </Typography>
            <Link
              size="small"
              sx={{ color: GlobalStyles.primaryText, cursor: "pointer" }}
              underline="always"
              onClick={() => handleDrawerActions("profile")}
            >
              Edit
            </Link>
          </Box>
          <hr />
          <Box padding={2}>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                First Name : <b>{userData.first_name || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Last Name : <b>{userData.last_name || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Email Address : <b>{userData.email_address || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Mobile Number : <b>{userData.mobile_number || "----"}</b>
              </Typography>
              <Typography></Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                {/* Mobile Number : <b>{userData.mobile_number || '----'}</b> */}
              </Typography>
              <Grid itex xs={12} textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  onClick={() => handleDrawerActions("password")}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid xs={12} md={6} sx={{ pl: "20px" }}>
        <Card variant="outlined" sx={{ p: 3, mb: 2, minHeight: 345 }}>
          <Box
            mb={2}
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Typography variant="h7" fontWeight={700} component="div">
              Store Details
            </Typography>
            <Link
              size="small"
              sx={{ color: GlobalStyles.primaryText, cursor: "pointer" }}
              underline="always"
              onClick={() => handleDrawerActions("store")}
            >
              Edit
            </Link>
          </Box>
          <hr />
          <Box padding={2}>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Store Name : <b>{userData?.store?.name || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Country : <b>{userData?.store?.country_code || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Address : <b> {userData?.store?.address_text || "----"} </b>
              </Typography>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={6} mb={2}>
                <Typography variant='h7' component='div'>
                  Region
                </Typography>
                <Typography>{userData?.store?.address_region || '----'}</Typography>
              </Grid>
              <Grid item xs={6} mb={2}>
                <Typography variant='h7' component='div'>
                  City
                </Typography>
                <Typography>{userData?.store?.address_city || '----'}</Typography>
              </Grid>
            </Grid> */}
            {/* <Grid container spacing={2}>
              <Grid item xs={6} mb={2}>
                <Typography variant='h7' component='div'>
                  Province
                </Typography>
                <Typography>{userData?.store?.address_province || '----'}</Typography>
              </Grid>
              <Grid item xs={6} mb={2}>
                <Typography variant='h7' component='div'>
                  Postal Code
                </Typography>
                <Typography>{userData?.store?.address_postal || '----'}</Typography>
              </Grid>
            </Grid> */}
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Company Email :{" "}
                <b> {userData?.store?.company_email || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Company Phone :{" "}
                <b> {userData?.store?.company_phone || "----"}</b>
              </Typography>
            </Grid>
          </Box>
        </Card>
      </Grid>

      <Grid xs={12} sx={{ pl: "20px" }}>
        <Card variant="outlined" sx={{ p: 3, mb: 2, height: "306px" }}>
          <Box
            mb={2}
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Typography variant="h7" fontWeight={700} component="div">
              Store Subscription Plan
            </Typography>
          </Box>
          <hr />
          <Box padding={2}>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Plan :{" "}
                <b>
                  {userData?.store_subscription?.subscription_name || "----"}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Billing Cycle :{" "}
                <b>
                  {userData?.store_subscription?.billing_cycle_name || "----"}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Date :{" "}
                <b>{`${userData.store_subscription?.start_date || "----"} - ${
                  userData.store_subscription?.expired_date || "----"
                }`}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                variant="h7"
                component="div"
              >
                Status : <b>{userData.store_subscription?.status || "----"}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="right">
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                color="success"
                onClick={handleInvoice}
                loadingIndicator={<CircularProgress size={16} color="info" />}
              >
                Request Invoice
              </LoadingButton>
            </Grid>
          </Box>
        </Card>
      </Grid>

      <CustomDrawer
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        setAction={setAction}
        action={action}
        showAddButton={false}
      >
        {action === "profile" && (
          <UpdateProfile
            productData={productData}
            setOpenDrawer={setOpenDrawer}
            // getStockList={getStockList}
            setOpenSnack={setOpenSnack}
            setSnackVariant={setSnackVariant}
            setErrMsg={setErrMsg}
            userData={userData}
            uuid={uuid}
            getUserDetails={getUserDetails}
            setOpenLoader={setOpenLoader}
          />
        )}
        {action === "store" && (
          <UpdateStore
            productData={productData}
            setOpenDrawer={setOpenDrawer}
            // getStockList={getStockList}
            setOpenSnack={setOpenSnack}
            setSnackVariant={setSnackVariant}
            setErrMsg={setErrMsg}
            userData={userData}
            uuid={uuid}
            setOpenLoader={setOpenLoader}
          />
        )}

        {action === "password" && (
          <UpdatePassword
            productData={productData}
            setOpenDrawer={setOpenDrawer}
            // getStockList={getStockList}
            setOpenSnack={setOpenSnack}
            setSnackVariant={setSnackVariant}
            setErrMsg={setErrMsg}
            uuid={uuid}
            setOpenLoader={setOpenLoader}
          />
        )}
      </CustomDrawer>

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
