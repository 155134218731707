import axios from "axios";
import apiRequest from "./ApiService";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { API_UPLOAD_URL } from '@env';

const apiEndPoint = "inventories";

// create table header
export const stockTableColumns = {
  stock_list: [
    // { id: 0, label: "Product" },
    // { id: 1, label: "Stock in total" },
    // { id: 2, label: "Stock out total" },
    // { id: 3, label: "Stock on hand" },
    // { id: 4, label: "Average Cost" },
    // { id: 5, label: "Status" },
    // { id: 6, label: "Action" },
    { id: 0, label: "Item Code" },
    { id: 1, label: "Product Name" },
    { id: 7, label: "Product Category" },
    { id: 2, label: "Available on hand" },
    { id: 3, label: "SRP Cost" },
    { id: 4, label: "Average Cost" },
    { id: 5, label: "Total Amount" },
    { id: 6, label: "Action" },
  ],
  stock_in: [
    { id: 0, label: "Date" },
    { id: 1, label: "Product Code" },
    { id: 2, label: "Item Name" },
    { id: 6, label: "Product Category" },
    { id: 3, label: "Qty" },
    { id: 4, label: "Price at Purchase" },
    { id: 5, label: "Remarks" },
  ],
  stock_out: [
    { id: 0, label: "Date" },
    { id: 1, label: "Product Code" },
    { id: 2, label: "Item Name" },
    { id: 5, label: "Product Category" },
    { id: 3, label: "Qty" },
    { id: 4, label: "Remarks" },
  ],
};

export const getStocks = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getStocksIn = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/stock-in` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getStocksOut = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/stock-out` + param);
  } catch (error) {
    console.log(error);
  }
};

export const addStock = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}/add-stock`, param);
  } catch (error) {
    console.log(error);
  }
};

export const removeStock = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}/remove-stock`, param);
  } catch (error) {
    console.log(error);
  }
};

export const multipleStockIn = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}/multiple-add-stock`, param);
  } catch (error) {
    console.log(error);
  }
};

export const exportStockIn = async (date_start, date_end) => {
  try {
    return await apiRequest
      .post(
        `/downloads?download_type=stock_in&date_start=` +
          date_start +
          `&date_end=` +
          date_end
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};

export const exportStockOut = async (date_start, date_end) => {
  try {
    return await apiRequest
      .post(
        `/downloads?download_type=stock_out&date_start=` +
          date_start +
          `&date_end=` +
          date_end
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};

export const downloadInventoryCSV = async (param = "") => {
  try {
    return await apiRequest.post(`/csv/download-inventory` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getImportHistory = async (param = "") => {
  try {
    return await apiRequest.get(`csv` + param);
  } catch (error) {
    console.log(error);
  }
};

export const fetchRunningInventory = async (param = "") => {
  try {
    return await apiRequest.post(`downloads` + param);
  } catch (error) {
    console.log(error);
    return error;
  }
};
